.home-page{
    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;

        


    }

    h1{
        color: #fff;
        font-size: 53px;
        margin:0;
        font-weight: 400;

        &::before{
            content: 'def greeting(){';
            color: #5ceeaf;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
            
        }

       

        img{
            position: absolute;
            margin-top: 5px;
            margin-left: -40px;
            width: 145px;
            height: auto;
            animation: fadeIn 1s 1.8s backwards;

        }
    }

    h2{
        color: #8d8d8d;
        margin: top 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;

    }
    
    .flat-button{

        color: #5ceeaf;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #5ceeaf;
        margin-top: 25px;
        float: left;
        animation: fadeIn1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background:#5ceeaf ;
            color: #333
        }

        &::after{
            content: '}';
            color: #5ceeaf;
            font-size: 18px;
            position: absolute;
            margin-top: 50px;
            margin-left: -130px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
            
        }

    }

    .portrait{
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
        border: 5px solid ;
        border-color: #5ceeaf;
        border-radius: 100%;

    }
}
