$primary-color:#5ceeaf;

@import 'animate.css';
@import url('https://fonts.cdnfonts.com/css/ocr-a-extended');

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea{
  font-family: 'OCR A Extended', sans-serif;                                             
  font-family: 'Helvetica Neue';
}
/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
    //animation: App-logo-translate infinite 4s ease-in-out;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

@keyframes App-logo-translate {
  0%{
    transform: translateY(0px);
  }
  50% {
    transform: translateY(50px);
  }100%{
    transform: translateY(0px);
  }
}

*/