
.text-zoneAbout{
    &:before{
        color: #5ceeaf;
        content: 'def aboutMe(){';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        margin-left: -10px;
        opacity: 0.6;
        line-height: 18px;
        animation: fadeIn 1s 1.7s backwards;
    }

     position: absolute;
        left: 10%;
        top:50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display:table-cell;
        max-height:90%;

        h1{
            font-size: 53px;
            color: #5ceeaf;
            font-weight: 400;
            margin-top:0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
        }
        p{
            font-size: 18px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1){
                animation-delay: 1.1s;
            }
            &:nth-of-type(2){
                animation-delay: 1.2s;
            }
            
        }
         &:after{
                content: '}';
                color: #5ceeaf;
                font-size: 18px;
                position: absolute;
                margin-top: 10px;
                margin-left: -10px;
                opacity: 0.6;
                line-height: 18px;

            }
}
.stage-cube-cont{
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.cube-spinner{
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style:preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50%  - 100px);

    div{
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255,255,255,0.4);
        font-size: 100px;
        display:flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightgray;

    }

    .face1{
        transform: translateZ(100px);
    }
    .face2{
        transform: rotateY(90deg) translateZ(100px);
    }
    .face3{
        transform: rotateY(90deg) rotatex(90deg) translateZ(100px);
    }
    .face4{
        transform: rotateY(180deg)rotateZ(90deg)translateZ(100px);
    }
    .face5{
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    }
    .face6{
        transform: rotateX(-90deg)translateZ(100px);
      }
}
@keyframes spincube{
        from,
    to{
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }16%{
        transform: rotateY(-90deg);
    }33%{
        transform: rotateY(-90deg) rotateZ(90deg);
    }50%{
        transform: rotateY(-180deg) rotateZ(90deg);
    }66%{
        transform: rotateY(-270deg) rotateZ(90deg);
    }83%{
        transform: rotateX(90deg);
    }
}


.loader {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top:0;
  bottom: 0;  
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
  
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #5ceeaf;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}