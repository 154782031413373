.text-zoneContact{
    &:before{
        color: #5ceeaf;
        content: 'def contactMe(){';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        margin-left: -10px;
        opacity: 0.6;
        line-height: 18px;
        animation: fadeIn 1s 1.7s backwards;
    }

        position: absolute;
        left: 10%;
        top:50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display:table-cell;
        max-height:90%;

        h1{
            font-size: 53px;
            color: #5ceeaf;
            font-weight: 400;
            margin-top:0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
        }
        p{
            font-size: 18px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1){
                animation-delay: 1.1s;
            }
            &:nth-of-type(2){
                animation-delay: 1.2s;
            }
            
        }
         &:after{
                content: '}';
                color: #5ceeaf;
                font-size: 18px;
                position: absolute;
                margin-top: 10px;
                margin-left: -10px;
                opacity: 0.6;
                line-height: 18px;

            }
}

.contact-form{
    width: 100%;
    margin-top: 20px;

    ul{
        padding: 0;
        margin: 0;

        li{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half{
            width: 49%;
            margin-left: 2%;
            float:left;
            clear:none;

            &:first-child{
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email']{
        width: 100%;
        border: 0;
        background-color: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
        border-radius: 4px;
    }

    textarea{
        width: 100%;
        border: 0;
        background-color: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        min-height: 150px;
        box-sizing: border-box;
        border-radius: 4px;

       

    }

    .flat-button{
        font-family: 'OCR A Extended', sans-serif; 
        color: #5ceeaf;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border-color: #5ceeaf;
        float: right;
        border-radius: 4px;

    }

}

.info-map{
    position: absolute;
    background-color: #000;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding:20px;
    color:#fff;
    font-family: 'OCR A Extended', sans-serif; 
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

}

.map-wrap{
    background: rgba(8,253, 126,0.1);
    float: right;
    width: 53%;
    height: 100%;

}

.leaflet-container{
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}