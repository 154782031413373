.page{
    width: 100%;
    height: 100%;
    position: absolute;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 556px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
    .contact-page{
    .text-zone{
        position: absolute;
        left: 10%;
        top:50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display:table-cell;
        max-height:90%;

        h1{
            font-size: 53px;
            color: #5ceeaf;
            font-weight: 400;
            margin-top:0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
        }
        p{
            font-size: 18px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1){
                animation-delay: 1.1s;
            }
            &:nth-of-type(2){
                animation-delay: 1.2s;
            }
            
        }
         &:after{
                content: '}';
                color: #5ceeaf;
                font-size: 18px;
                position: absolute;
                margin-top: 10px;
                margin-left: -10px;
                opacity: 0.6;
                line-height: 18px;

            }

    }

}